import EIMZOClient from '@/shared/sign/vue-e-imzo-client'
import dates from '@/shared/sign/sign-date-utils'
import Swal from 'sweetalert2'

const errorCAPIWS = 'Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.'
const errorBrowserWS = 'Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.'
const errorUpdateApp = 'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>'
const errorWrongPassword = 'Пароль неверный.'

export let signers = []

function getESign () {
    EIMZOClient.checkVersion(function (major, minor) {
        const installedVersion = parseInt(major) * 100 + parseInt(minor)
        if (installedVersion < 337) {
            uiShowMessage(errorUpdateApp)
        } else {
            EIMZOClient.installApiKeys(function () {
                uiLoadKeys()
            }, function (e, r) {
                if (r) {
                    uiShowMessage(r)
                } else {
                    wsError(e)
                }
            })
        }
    }, function (e, r) {
        if (r) {
            uiShowMessage(r)
        } else {
            wsError(e)
        }
    })
}

let uiLoadKeys = function () {
    EIMZOClient.listAllUserKeys(function (o, i) {
        return "itm-" + o.serialNumber + "-" + i
    }, function (itemId, v) {
        v.expired = dates.compare(new Date(), v.validTo) > 0
        return {
            id: itemId,
            vo: v,
            text: v.CN
        }
    }, function (items) {
        signers = items
    }, function () {
        uiShowMessage(errorCAPIWS)
    })
}

let uiShowMessage = function (message) {
    Swal.fire('Diqqat!', message, 'warning')
}

let wsError = function (e) {
    if (e) {
        uiShowMessage(errorCAPIWS)
    } else {
        uiShowMessage(errorBrowserWS)
    }
}

const checkPersonKey = function (type, companyTin, PINFL, signer){
    if (type === 'ENTITY'){
        return signer.vo.TIN === companyTin
    } else {
        return signer.vo.PINFL === PINFL && !(signer.vo.TIN === companyTin)
    }
}

const SIGN = {
    signInit: function() {
        signers = []
        getESign()
    },
    singer: function (type, companyTin, PINFL) {
        let signer = null

        if (signers.length === 0) return signer;

        for (let i = 0; i < signers.length; i++) {
            if (checkPersonKey(type, companyTin, PINFL, signers[i])){
                if (!signers[i].vo.expired) {
                    signer = signers[i]
                    break
                }
                if (signer === null || signer.vo.validTo < signers[i].vo.validTo) {
                    signer = signers[i]
                }
            }
        }

        if (!signer) {
            uiShowMessage((type === 'ENTITY' ? ('PINFL : ' + PINFL + ' va INN : ' + companyTin) : ('PINFL : ' + PINFL)) + ' Imzolovchining elektron kaliti topilmadi!')
        } else if (signer.vo.expired) {
            uiShowMessage('Sizdagi elektron kalit yaroqlilik muddati tugagan!')
        }

        return signer
    },
    errorMessage: function (r, e) {
        if (r) {
            if (r.indexOf("BadPaddingException") !== -1) {
                uiShowMessage(errorWrongPassword)
            } else {
                uiShowMessage(r)
            }
        } else {
            uiShowMessage(errorBrowserWS)
        }
        if (e) wsError(e)
    },
    singers: function () {
        return signers
    }
}

export default SIGN