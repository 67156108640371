<template>
  <div class="row">
    <div class="col-6 text-left">
      <template v-if="signer">
        <div>
          <label><b>Imzolovchi : </b></label>
          <label class="text-info"><b class="ml-3">{{ signer.text }}</b></label>
        </div>
        <div>
          <label><b>Role : </b></label>
          <label class="text-info"><b class="ml-3">{{ signer.vo.T }}</b></label>
        </div>
        <div v-if="signer.vo.TIN">
          <label><b>INN : </b></label>
          <label class="text-info"><b class="ml-3">{{ signer.vo.TIN }}</b></label>
        </div>
        <div v-if="signer.vo.PINFL">
          <label><b>PINFL : </b></label>
          <label class="text-info"><b class="ml-3">{{ signer.vo.PINFL }}</b></label>
        </div>
        <div>
          <label><b>Tashkilot : </b></label>
          <label class="text-info"><b class="ml-3">{{ signer.vo.O }}</b></label>
        </div>
        <div>
          <label><b>Muddati : </b></label>
          <label v-if="signer.vo.expired" class="text-danger">
            <b class="ml-3">{{toDate}}
              <span class="ml-3"> ( Tugagan )</span>
            </b>
          </label>
          <label v-else class="text-success">
            <b class="ml-3">{{toDate}}
              <span class="ml-3"> ( Amalda )</span>
            </b>
          </label>
        </div>
      </template>
    </div>
    <div class="col-6">
      <h5><b class="red">Имзо куйишдан олдин хужжат билан танишиб чикинг</b></h5>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from '@/shared/utils';
export default {
  name: "signer-info",
  props: {
    signer: Object
  },
  computed: {
    toDate() {
      return formatDateTime(this.signer.vo.validTo);
    }
  }
}
</script>

<style scoped>

</style>