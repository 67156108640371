
/* eslint-disable no-undef */
// eslint-disable-next-line no-undef
// eslint-disable-next-line
/* eslint-disable */

var CAPIWS = (typeof EIMZOEXT !== 'undefined') ? EIMZOEXT :{

    URL: (window.location.protocol.toLowerCase() === "https:" ? "wss://127.0.0.1:64443" : "ws://127.0.0.1:64646") + "/service/cryptapi",

    callFunction: function(funcDef, callback, error){
        if (!window.WebSocket){
            if(error)
                error();
            return;
        }
        var socket;
        try{
            socket = new WebSocket(this.URL);
        } catch (e){
            error(e);
        }
        socket.onerror = function(e){
            if(error)
                error(e);
        };
        socket.onclose = function(e){
            if(error) {
                if(e.code != 1000){
                    error(e.code);
                }
            }
        };
        socket.onmessage = function(event){
            var data = JSON.parse(event.data);
            socket.close();
            callback(event,data);
        };
        socket.onopen = function(){
            socket.send(JSON.stringify(funcDef));
        };
    },
    version: function(callback, error){
        if (!window.WebSocket){
            if(error)
                error();
            return;
        }
        var socket;
        try{
            socket = new WebSocket(this.URL);
        } catch (e){
            error(e);
        }
        socket.onerror = function(e){
            if(error)
                error(e);
        };
        socket.onclose = function(e){
            if(error) {
                if(e.code != 1000){
                    error(e.code);
                }
            }
        };
        socket.onmessage = function(event){
            var data = JSON.parse(event.data);
            socket.close();
            callback(event,data);
        };
        socket.onopen = function(){
            var o = {name: 'version'};
            socket.send(JSON.stringify(o));
        };
    },
    apidoc: function(callback, error){
        if (!window.WebSocket){
            if(error)
                error();
            return;
        }
        var socket;
        try{
            socket = new WebSocket(this.URL);
        } catch (e){
            error(e);
        }
        socket.onerror = function(e){
            if(error)
                error(e);
        };
        socket.onclose = function(e){
            if(error) {
                if(e.code != 1000){
                    error(e.code);
                }
            }
        };
        socket.onmessage = function(event){
            var data = JSON.parse(event.data);
            socket.close();
            callback(event,data);
        };
        socket.onopen = function(){
            var o = {name: 'apidoc'};
            socket.send(JSON.stringify(o));
        };
    },
    apikey: function(domainAndKey, callback, error){
        if (!window.WebSocket){
            if(error)
                error();
            return;
        }
        var socket;
        try{
            socket = new WebSocket(this.URL);
        } catch (e){
            error(e);
        }
        socket.onerror = function(e){
            if(error)
                error(e);
        };
        socket.onclose = function(e){
            if(error) {
                if(e.code != 1000){
                    error(e.code);
                }
            }
        };
        socket.onmessage = function(event){
            var data = JSON.parse(event.data);
            socket.close();
            callback(event,data);
        };
        socket.onopen = function(){
            var o = {name: 'apikey', arguments: domainAndKey};
            socket.send(JSON.stringify(o));
        };
    }
};

export default CAPIWS;
