var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-left"},[(_vm.signer)?[_c('div',[_vm._m(0),_c('label',{staticClass:"text-info"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.signer.text))])])]),_c('div',[_vm._m(1),_c('label',{staticClass:"text-info"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.signer.vo.T))])])]),(_vm.signer.vo.TIN)?_c('div',[_vm._m(2),_c('label',{staticClass:"text-info"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.signer.vo.TIN))])])]):_vm._e(),(_vm.signer.vo.PINFL)?_c('div',[_vm._m(3),_c('label',{staticClass:"text-info"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.signer.vo.PINFL))])])]):_vm._e(),_c('div',[_vm._m(4),_c('label',{staticClass:"text-info"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.signer.vo.O))])])]),_c('div',[_vm._m(5),(_vm.signer.vo.expired)?_c('label',{staticClass:"text-danger"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.toDate)+" "),_c('span',{staticClass:"ml-3"},[_vm._v(" ( Tugagan )")])])]):_c('label',{staticClass:"text-success"},[_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.toDate)+" "),_c('span',{staticClass:"ml-3"},[_vm._v(" ( Amalda )")])])])])]:_vm._e()],2),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("Imzolovchi : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("Role : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("INN : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("PINFL : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("Tashkilot : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('b',[_vm._v("Muddati : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('h5',[_c('b',{staticClass:"red"},[_vm._v("Имзо куйишдан олдин хужжат билан танишиб чикинг")])])])
}]

export { render, staticRenderFns }